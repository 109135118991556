import { formatDateTime } from "../../utils/date";




export const getVisitColumns = () => {
    return [
        {
            field: "visits",
            headerName: "Customer Name",
            flex: 1,
            renderCell: (params) => params?.row?.memberId?.forenameMember + " " + params?.row?.memberId?.surnameMember
        },
        {
            field: "lamplightID",
            headerName: "Customer LampLight ID",
            flex: 1,
            renderCell: (params) => params?.row?.memberId?.lamplightID
        },
        {
            field: "createdAt",
            headerName: "Visit Date",
            flex: 1,
            renderCell: (params) => formatDateTime(params?.row?.createdAt)

        },
        {
            field: "Shopkeeper",
            headerName: "Served By",
            flex: 1,
            renderCell: (params) => params?.row?.servedBy?.firstName + " " + params?.row?.servedBy?.lastName
        },
    ];

}

export const visitDownloadCols = [
    {
        headerName: "Customer First Name",
        field1: "memberId",
        field2: "forenameMember",
        type: "text"
    },
    {
        headerName: "Customer Last Name",
        field1: "memberId",
        field2: "surnameMember",
        type: "text"
    },
    {
        headerName: "Customer Lamplight ID",
        field1: "memberId",
        field2: "lamplightID",
        type: "text"
    },
    {
        field: "createdAt",
        headerName: "Visit Date",
        type: "date"
    },
    {
        headerName: "Served By First Name",
        field1: "servedBy",
        field2: "firstName",
        type: "text"
    },
    {
        headerName: "Served By Last Name",
        field1: "servedBy",
        field2: "lastName",
        type: "text"
    },
]

export const getServedColumns = () => {
    return [
        {
            field: "Served By",
            headerName: "Served By",
            flex: 1,
            renderCell: (params) => params?.row?.servedBy?.firstName + " " + params?.row?.servedBy?.lastName

        },
        {
            field: "createdAt",
            headerName: "Served Date",
            flex: 1,
            renderCell: (params) => formatDateTime(params?.row?.createdAt)

        },
        {
            field: "visits",
            headerName: "Customer Name",
            flex: 1,
            renderCell: (params) => params?.row?.memberId?.forenameMember + " " + params?.row?.memberId?.surnameMember
        },
        {
            field: "lamplightID",
            headerName: "Customer LampLight ID",
            flex: 1,
            renderCell: (params) => params?.row?.memberId?.lamplightID
        },
    ];

}

export const servedDownloadCols = [
    {
        headerName: "Served By First Name",
        field1: "servedBy",
        field2: "firstName",
        type: "text"
    },
    {
        headerName: "Served By Last Name",
        field1: "servedBy",
        field2: "lastName",
        type: "text"
    },
    {
        field: "createdAt",
        headerName: "Visit Date",
        type: "date"
    },
    {
        headerName: "Customer First Name",
        field1: "memberId",
        field2: "forenameMember",
        type: "text"
    },
    {
        headerName: "Customer Last Name",
        field1: "memberId",
        field2: "surnameMember",
        type: "text"
    },
    {
        headerName: "Customer Lamplight ID",
        field1: "memberId",
        field2: "lamplightID",
        type: "text"
    }
]
