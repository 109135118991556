import { Add } from '@mui/icons-material';
import { Button, Divider, Grid, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { object } from 'yup';
import ImageUpload from '../../../shared/ImageUpload';
import MyDialog from '../../../shared/MyDialog/MyDialog';
import InputField from '../../../shared/RHF/InputField';
import RHFCheckBox from '../../../shared/RHF/RHFCheckBox';
import RHFDatePicker from '../../../shared/RHF/RHFDatePicker';
import RHFRadioGroup from '../../../shared/RHF/RHFRadioGroup';
import styles from '../user.module.scss';
import { formMemberSchema, YupMemberSchema } from './helper';
import memberStyles from './member.module.scss';
import SignaturePad from './SignaturePad';
import SignatureUpload from './SignatureUpload';
import { CREATE_MEMBER, UPDATE_MEMBER } from '../../../../redux/types/member.types';
import dayjs from 'dayjs';

const MemberForm = ({ type, handleClose, editUserData }) => {

    const dispatch = useDispatch();
    const isEditMode = !!Object.keys(editUserData).length;
    let userSchema = object(YupMemberSchema(isEditMode));

    const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm(formMemberSchema(userSchema));
    console.log("errors", errors);
    const handleCreateUser = (data) => {
        console.log("data", data)
        const formData = new FormData();

        // Append all fields from `data` to FormData
        Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
                formData.append(key, JSON.stringify(data[key]));
            } else if (data[key] && data[key].file instanceof File) {
                formData.append(key, data[key].file);
            } else {
                // Otherwise, append the field normally
                formData.append(key, data[key]);
            }
        });



        if (isEditMode) {
            // Handle update logic
            dispatch({ type: UPDATE_MEMBER, payload: { formData, id: data?._id } });
        } else {
            // Dispatch create user action with formData
            dispatch({ type: CREATE_MEMBER, payload: formData });
        }

    };


    useEffect(() => {
        if (isEditMode) {
            const { dobMember, memberShipPaidDate, dobsAbove16, dobUnder16, photo1, photo2, photo3, photo4, ...otherFields } = editUserData || {};

            Object.entries(otherFields)?.forEach(([key, value]) => {
                setValue(key, value);
            });
            console.log("dobMember", dobMember);

            [photo1, photo2, photo3, photo4].forEach((photo, index) => {
                setValue(`photo${index + 1}`, { file: null, previewUrl: photo });
            });
            setValue("dobMember", dayjs(dobMember));
            setValue("memberShipPaidDate", dayjs(memberShipPaidDate));

            dobsAbove16?.forEach((item, idx) => {
                if (item) {
                    setValue(`dobsAbove16.${idx}`, dayjs(item));
                }
            });

            dobUnder16?.forEach((item, idx) => {
                if (item) {
                    setValue(`dobUnder16.${idx}`, dayjs(item));
                }
            });
        }
    }, [editUserData, reset, isEditMode, setValue]);

    const [signaturePadModel, setSignaturePadModel] = useState(false);
    const [selectedSignatureType, setSelectedSignatureType] = useState()
    const handleSignaturePadOpen = (key) => {
        setSelectedSignatureType(key)
        setSignaturePadModel(true);
    }
    const handleSignaturePadClose = () => {
        setSignaturePadModel(false);
    }

    const handleSignatureSubmit = (key, previewUrl, file) => {
        if (key === "signatureOfShopper") {
            setValue("signatureOfShopper", { file, previewUrl });
        } else {
            setValue("signatureOfApplicant", { file, previewUrl });
        }
        handleSignaturePadClose();
    }

    useEffect(() => {
        const handleCardListner = ({ detail: id }) => {
            console.log("Handling Form Reader")
            setValue("cardNumber", id)
        }



        window.addEventListener('card-scanned', handleCardListner)

        return () => {
            window.removeEventListener('card-scanned', handleCardListner);
        };
    }, [setValue]);


    console.log("err", errors)
    return (
        <>
            <form className={styles.formContainer} onSubmit={handleSubmit(handleCreateUser)}>
                <Grid spacing={3} container>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'forenameMember'} placeHolder={'Forename(s) of Applicant'} label={"Forename(s) of Applicant"} type={'text'} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'surnameMember'} placeHolder={'Surname of Applicant'} label={"Surname of Applicant"} type={'text'} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'forenameShopper'} placeHolder={'Forename(s) of Nominated Shopper'} label={"Forename(s) of Nominated Shopper"} type={'text'} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'surnameShopper'} placeHolder={'Surname of Nominated Shopper'} label={"Surname of Nominated Shopper"} type={'text'} />
                    </Grid>
                    <Grid item xs={4}>
                        <InputField control={control} errors={errors} name={'mobileNum'} placeHolder={'Telephone Number'} label={"Telephone Number"} type={'text'} />
                    </Grid>
                    <Grid item xs={4}>
                        <InputField control={control} errors={errors} name={'lamplightID'} placeHolder={'Lamplight ID'} label={"Lamplight ID"} type={'text'} />
                    </Grid>

                    <Grid item xs={4}>
                        <RHFDatePicker
                            control={control}
                            errors={errors}
                            name="dobMember"
                            label="Date of Birth of Applicant *"
                            views={['day']}
                            disableFuture={true}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <RHFDatePicker
                            control={control}
                            errors={errors}
                            name="memberShipPaidDate"
                            label="Membership Paid Date *"
                            views={['day']}
                            disableFuture={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'cardNumber'} placeHolder={'Membership Card Number'} label={"Membership Card Number"} type={'text'} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'address'} placeHolder={'Address'} label={"Address"} type={'text'} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'postCode'} placeHolder={'PostCode'} label={"PostCode"} type={'text'} />
                    </Grid>
                    <Grid item xs={4}>
                        <RHFCheckBox control={control} errors={errors} name={'addressChecked'} label={"Address Checked"} />
                    </Grid>
                    <Grid item xs={4}>
                        <RHFCheckBox control={control} errors={errors} name={'proofOfId'} label={"Proof of Id"} />
                    </Grid>
                    <Grid item xs={6}>
                        <RHFRadioGroup control={control} errors={errors} name={'isPropertyHouse'} label={"Is property a house in multiple occupation?"} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Dates of birth of any other over 16 year olds in household:</Typography>
                    </Grid>
                    <Grid item container ml={3} xs={12} className={memberStyles.dobsContainer}>
                        {Array.from({ length: 8 }).map((_, index) => (
                            <Grid item xs={3} p={1} key={index}>
                                <RHFDatePicker
                                    control={control}
                                    disableFuture={true}
                                    errors={errors}
                                    name={`dobsAbove16.${index}`}
                                    label={`Person ${index + 1} D.O.B`}
                                    views={['day']}
                                    onChange={(date) => setValue(`dobsAbove16.${index}`, date)}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>Dates of birth of all dependent children 16 year olds or under:</Typography>
                    </Grid>
                    <Grid item container ml={3} xs={12} className={memberStyles.dobsContainer}>
                        {Array.from({ length: 8 }).map((_, index) => (
                            <Grid item xs={3} p={1} key={index}>
                                <RHFDatePicker
                                    control={control}
                                    disableFuture={true}
                                    errors={errors}
                                    name={`dobUnder16.${index}`}
                                    label={`Child ${index + 1} D.O.B`}
                                    views={['day']}
                                    onChange={(date) => setValue(`dobUnder16.${index}`, date)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body2'>Total Number of people (Including Children) in household:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'totalNumberOfPeople'} placeHolder={'Total Number of people (Including Children) in household'} label={""} type={'text'} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body2'>Details of any food allergies, e.g., lactose intolerance? Or special dietary needs such as type 1 diabetes, vegetarian, gluten-free, halal? If so, please give details</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'allergyDetails'} placeHolder={'Please enter any dietary requirements. If none, please type n/a.'} label={""} type={'text'} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body2'>Do you have any pets: if so, give details: e.g. 1 dog, 2 cats</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'totalNumberOfPets'} placeHolder={'Please enter any household pets. If none, please type n/a.'} label={""} type={'text'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>


                    <Grid item display={'flex'} flexDirection={'column'} gap={2} xs={6}>
                        <Typography variant='body2' >Signature of Volunteer *</Typography>
                        <SignatureUpload handleSignaturePadOpen={handleSignaturePadOpen} handleSignatureSubmit={handleSignatureSubmit} type={"signatureOfShopper"} />
                        {watch("signatureOfShopper") &&
                            <img className={memberStyles.signatureCanvas} src={watch("signatureOfShopper")?.previewUrl ? watch("signatureOfShopper")?.previewUrl : watch("signatureOfShopper")} height={300} width={300} alt='signatureOfShopper' />
                        }
                    </Grid>
                    <Grid item display={'flex'} flexDirection={'column'} gap={2} xs={6}>
                        <Typography variant='body2' >Applicant Signature *</Typography>
                        <SignatureUpload handleSignaturePadOpen={handleSignaturePadOpen} handleSignatureSubmit={handleSignatureSubmit} type={"signatureOfApplicant"} />
                        {watch("signatureOfApplicant") &&
                            <img className={memberStyles.signatureCanvas} src={watch("signatureOfApplicant")?.previewUrl ? watch("signatureOfApplicant")?.previewUrl : watch("signatureOfApplicant")} height={300} width={300} alt='signatureOfApplicant' />
                        }
                    </Grid>
                    <Grid item container xs={12}>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <Grid item xs={3} p={1} key={index}>
                                <ImageUpload
                                    control={control}
                                    name={`photo${index + 1}`}
                                    label={`Photo ${index + 1}`}
                                    errors={errors}
                                    index={index}
                                />
                            </Grid>
                        ))}
                    </Grid>




                    <Grid display={'flex'} justifyContent={'flex-end'} item xs={12}>
                        <Button
                            sx={{ p: 1, px: 3 }}
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            type='submit'
                        >
                            {isEditMode ? "Update Member" : "Create Member"}
                        </Button>
                    </Grid>

                </Grid>
            </form>
            <MyDialog title={"Draw Signature"} maxWidth='sm' open={signaturePadModel} handleClose={handleSignaturePadClose} >
                <SignaturePad handleSignatureSubmit={handleSignatureSubmit} type={selectedSignatureType} />
            </MyDialog>
        </>
    )
}

export default memo(MemberForm);
