import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sharedActions } from '../../../redux/slice/shared.slice';
import { GET_VISITS } from '../../../redux/types/shared.types';
import styles from '../../modules/user/user.module.scss';
import MyTable from '../../shared/MyTable/MyTable';
import { handleDownloadCsv } from '../../utils/csv';
import { getVisitColumns, visitDownloadCols } from './helper';
import VisitToolbar from './VisitsToolBar';

const VisitsTable = () => {
    const dispatch = useDispatch();
    const [time, setTime] = useState({
        from: null,
        to: null,
    })

    const [searchTerm, setSearchTerm] = useState("");
    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        const payload = {
            searchTerm: value,
            ...time,
        }
        dispatch({ type: GET_VISITS, payload })
    };

    useEffect(() => {
        const payload = {
            searchTerm: "",
            ...time
        }
        dispatch({ type: GET_VISITS, payload })
    }, [dispatch, time])

    const visits = useSelector(sharedActions.selectors.getVisits)


    const handleTime = (key, value) => {
        setTime((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    const visitColumns = getVisitColumns();

    return (
        <>
            <Grid container className={styles.userContainer}>
                <Grid item xs={12}>
                    <VisitToolbar
                        searchTerm={searchTerm}
                        placeHolder={"Search Visit"}
                        handleSearch={handleSearch}
                        handleClickBtn2={() => handleDownloadCsv(visits, visitDownloadCols, `Visits ${dayjs().format("DD_MM_YYYY")}`)}
                        handleTime={handleTime}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MyTable
                        checkboxSelection={false}
                        columns={visitColumns}
                        rows={visits}
                        id={(row) => row?._id}
                    />
                </Grid>
                {console.log("visits", visits)}
            </Grid>
        </>
    )
}

export default VisitsTable
